import React, { Component } from 'react';
import { formConsumer } from 'core/form';
import { dateTimeFormatter } from 'core/util/dateUtils';
import AdminTable from 'app/components/admin/AdminTable';
import { Button, Flex } from 'core/components';
import { CELL_TYPES } from 'core/components/table';
import { FiEdit } from 'react-icons/fi';
import { withRouter } from 'react-router-dom';

@withRouter
@formConsumer
export default class CompanyUsers extends Component {
  handleEditUser = user => {
    const { history } = this.props;
    history.push(`/v4/admin/${user.get('company_id')}/user/${user.get('id')}`);
  };

  getColumns = () => [
    {
      label: 'ID',
      name: 'id',
      width: 80
    },
    {
      label: 'Name',
      name: 'user_full_name',
      flexBasis: 200
    },
    {
      label: 'Email',
      name: 'user_email'
    },
    {
      label: 'Level',
      name: 'userLevel',
      computed: true
    },

    {
      label: 'Created',
      name: 'cdate',
      renderer: dateTimeFormatter
    },
    {
      label: 'Last Login',
      name: 'last_login',
      renderer: dateTimeFormatter
    },
    {
      type: CELL_TYPES.ACTION,
      actions: [model => <Button key="edit" onClick={() => this.handleEditUser(model)} icon={FiEdit} minimal small />]
    }
  ];

  render() {
    const { form } = this.props;
    return (
      <Flex flexDirection="column" flex={1} overflow="auto">
        <AdminTable
          collection={form.model.get('users')}
          columns={this.getColumns()}
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          fetchCollectionOnMount={false}
          flexed
        />
      </Flex>
    );
  }
}
