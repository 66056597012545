import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import storeLoader from 'app/stores/storeLoader';
import Page from 'app/components/page/Page';
import { Suspense } from 'core/components';
import UserDetailsForm from 'app/views/settings/users/UserDetailsForm';

@storeLoader('$sudo.companyAdminCollection')
@withRouter
@observer
export default class CompanyUserDetails extends Component {
  state = {
    selectedCompanyModel: undefined,
    selectedUserModel: undefined
  };

  componentDidUpdate() {
    const { match, $sudo } = this.props;
    const { selectedUserModel } = this.state;

    if ($sudo.companyAdminCollection.hasFetched && selectedUserModel?.id !== match.params.id) {
      const model = $sudo.companyAdminCollection.get(match.params.companyId);
      if (model) {
        model.fetch().then(() => {
          const userModel = model.get('users').get(match.params.id);
          if (userModel) {
            userModel.fetchFullProfile().then(() =>
              this.setUserModel({
                selectedCompanyModel: model,
                selectedUserModel: model.get('users').get(match.params.id)
              })
            );
          }
        });
      }
    }
  }

  setUserModel = ({ selectedCompanyModel, selectedUserModel }) => {
    this.setState({ selectedCompanyModel, selectedUserModel });
  };

  render() {
    const { loading } = this.props;
    const { selectedCompanyModel, selectedUserModel } = this.state;

    let breadCrumbTitle = 'Company Admin';
    let title = 'Manage User';
    if (selectedUserModel) {
      breadCrumbTitle = selectedCompanyModel.get('company_name_full');
      title = selectedUserModel.get('user_full_name');
    }

    return (
      <Page
        title={title}
        parentLinks={[{ link: `/v4/admin/${selectedCompanyModel?.id}`, label: breadCrumbTitle }]}
        height="100%"
      >
        <Suspense loading={loading}>
          {selectedUserModel && <UserDetailsForm title={title} model={selectedUserModel} />}
        </Suspense>
      </Page>
    );
  }
}
