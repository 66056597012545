import React, { Component } from 'react';
import { CLOUD_PROVIDERS, PLAN_TYPES, PLAN_SIZES } from 'app/util/constants';
import { deviceTypeByKflow, zeroToText } from 'app/util/utils';
import { observer } from 'mobx-react';
import { Checkbox, CheckboxGroup, Field, FormDialog, InputGroup, Select, TextArea } from 'core/form';
import { Box, Flex } from 'core/components';
import LargeLabeledValue from 'app/components/LargeLabeledValue';

function getPlanTypeOptions() {
  return Object.values(PLAN_TYPES).map(planType => ({ value: planType.code, label: planType.name }));
}

const options = {
  name: 'Company Plan'
};
@observer
export default class CompanyPlansFormDialog extends Component {
  get fields() {
    return {
      name: {
        label: 'Name',
        rules: 'required'
      },
      description: {
        label: 'Description (visible to customers)',
        rules: 'required'
      },
      max_fps: {
        label: 'Max FPS',
        rules: 'required|integer|min:1',
        defaultValue: 2000
      },
      bgp: {
        label: 'Enable BGP',
        defaultValue: true
      },
      fast_retention: {
        label: (
          <span key="fast_retention">
            Trending Data Retention{' '}
            <span key="fast_retention_sub" className="pt-text-muted">
              (days)
            </span>
          </span>
        ),
        rules: 'required',
        defaultValue: 90
      },
      full_retention: {
        label: (
          <span key="full_retention">
            Forensic Data Retention{' '}
            <span key="full_retention_sub" className="pt-text-muted">
              (days)
            </span>
          </span>
        ),
        rules: 'required',
        defaultValue: 90
      },
      max_devices: {
        label: 'Maximum # of Data Sources',
        rules: 'required',
        defaultValue: 25
      },
      deviceTypes: {
        label: 'Allowed Device Types',
        // See utils.kflowTypeLabelMap for kflowType => label translation.
        defaultValue: deviceTypeByKflow('router', 'host-nprobe-dns-www').map(dt => dt.id),
        options: deviceTypeByKflow('router', 'host-nprobe-basic', 'host-nprobe-dns-www', 'host').map(dt => ({
          value: dt.id,
          label: dt.label
        })),
        rules: 'required',
        transform: {
          in: value => value.map(type => type.id || type)
        }
      },
      'metadata.type': {
        label: 'Plan Type',
        defaultValue: 'legacy',
        options: getPlanTypeOptions()
      },
      'metadata.cloudProvider': {
        label: 'Cloud Provider',
        options: [
          { value: CLOUD_PROVIDERS.GCE.id, label: CLOUD_PROVIDERS.GCE.name },
          { value: CLOUD_PROVIDERS.AWS.id, label: CLOUD_PROVIDERS.AWS.name },
          { value: CLOUD_PROVIDERS.AZURE.id, label: CLOUD_PROVIDERS.AZURE.name },
          { value: 'universal', label: 'Universal' }
        ]
      },
      'metadata.pakFps': {
        label: 'Pak FPS'
      },
      'metadata.isPak': {
        label: 'Pak FPS Enforcement',
        defaultValue: false
      },
      'metadata.resolution': {
        label: 'Resolution',
        defaultValue: 'std',
        options: [
          { value: 'std', label: 'Standard' },
          { value: 'enh', label: 'Enhanced' }
        ]
      },
      'metadata.size': {
        label: 'Plan Size'
      },
      'metadata.paidCredits': {
        label: 'Paid Credits',
        defaultValue: 0
      },
      'metadata.freeCredits': {
        label: 'Free Credits',
        defaultValue: 0
      }
    };
  }

  isCloudPlan = form => {
    const typeField = form.getField('metadata.type');
    return typeField && (typeField.value === PLAN_TYPES.cloud.code || typeField.value === PLAN_TYPES.universalpak.code);
  };

  isMetricsPlan = form => {
    const typeField = form.getField('metadata.type');
    return typeField && typeField.value === PLAN_TYPES.metrics.code;
  };

  isPak = form => {
    const typeField = form.getField('metadata.type');
    return (
      typeField &&
      (typeField.value === PLAN_TYPES.cloud.code ||
        typeField.value === PLAN_TYPES.flowpak.code ||
        typeField.value === PLAN_TYPES.universalpak.code)
    );
  };

  isUniversalPlan = form => {
    const typeField = form.getField('metadata.type');
    return typeField && typeField.value === PLAN_TYPES.universalpak.code;
  };

  isPlatformPlan = form => {
    const typeField = form.getField('metadata.type');
    return typeField && (typeField.value === PLAN_TYPES.edge.code || typeField.value === PLAN_TYPES.core.code);
  };

  isSyntheticsPlan = form => {
    const typeField = form.getField('metadata.type');
    return typeField && typeField.value === PLAN_TYPES.ksynth.code;
  };

  updateCloudProviderRules = form => {
    const providerField = form.getField('metadata.cloudProvider');
    if (providerField) {
      providerField.setRules(this.isCloudPlan(form) ? 'required' : '');
    }
  };

  updatePakRules = form => {
    const pakFpsField = form.getField('metadata.pakFps');
    pakFpsField.setRules(this.isPak(form) ? 'required|numeric' : '');
    form.getField('max_fps').setLabel(this.isPak(form) ? 'Max FPS (incl. buffer)' : 'Max FPS');
  };

  updateMetricsRules = form => {
    // Labels get applied on all updates.
    const { full_retention, max_devices, max_fps } = this.fields;
    if (this.isMetricsPlan(form)) {
      form.getField('max_fps').setLabel('Max Metrics/second');
      form.getField('max_devices').setLabel('Max Devices');
      form.getField('full_retention').setLabel('Data Retention (days)');
    } else if (!this.isPak(form)) {
      form.getField('max_fps').setLabel(max_fps.label);
    } else {
      form.getField('max_devices').setLabel(max_devices.label);
      form.getField('full_retention').setLabel(full_retention.label);
    }
  };

  getPlanSizeOptions = form => {
    const typeField = form.getField('metadata.type');
    const type = typeField && typeField.value;
    const allOptions = Object.values(PLAN_SIZES).map(psObj => ({ value: psObj.code, label: psObj.name }));

    // If plan type is edge, pull out 1GB option, otherwise return all.
    if (type === PLAN_TYPES.edge.code) {
      return allOptions.filter(opt => opt.value !== PLAN_SIZES['1GB'].code);
    }
    return allOptions;
  };

  updatePlatformPlanRules = form => {
    const sizeField = form.getField('metadata.size');
    const resolutionField = form.getField('metadata.resolution');
    const isPlatformPlan = this.isPlatformPlan(form);
    resolutionField.setRules(isPlatformPlan ? 'required' : '');
    sizeField.setRules(isPlatformPlan ? 'required' : '');
  };

  updateRules = form => {
    this.updateCloudProviderRules(form);
    this.updatePakRules(form);
    this.updateMetricsRules(form);
    this.updatePlatformPlanRules(form);
  };

  handleSave = (form, values) => {
    const { model, company } = this.props;

    return model.save(values).then(() => company.fetch());
  };

  handleExpire = () => {
    const { model, company } = this.props;

    return model.destroy({ remove: false }).then(() => company.fetch());
  };

  handlePlanTypeChange = planTypeFieldState => {
    const { form } = planTypeFieldState;
    const routerDeviceTypeId = deviceTypeByKflow('router')[0].id;
    const kProbeDeviceTypeId = deviceTypeByKflow('host-nprobe-dns-www')[0].id;

    // if cloud set device type to kprobe
    const type = planTypeFieldState.value;

    if (this.isUniversalPlan(form)) {
      form.getField('deviceTypes').setValue([routerDeviceTypeId, kProbeDeviceTypeId]);
      form.getField('metadata.cloudProvider').setValue('universal');
    } else if (this.isCloudPlan(form)) {
      form.getField('deviceTypes').setValue([kProbeDeviceTypeId]);
      form.getField('metadata.cloudProvider').setValue('universal');
    } else {
      form.getField('deviceTypes').setValue([routerDeviceTypeId, kProbeDeviceTypeId]);
      form.getField('metadata.cloudProvider').setValue('');
    }

    if (this.isPak(form)) {
      form.getField('metadata.pakFps').setValue(2000);
      form.getField('max_fps').setValue(4000);
      form.getField('metadata.isPak').setValue(true);
      form.getField('max_devices').setValue(this.isCloudPlan(form) ? 10000 : 100);
    } else {
      form.getField('metadata.pakFps').setValue('');
      form.getField('max_fps').setValue(this.isMetricsPlan(form) ? 15000 : 2000);
      form.getField('metadata.isPak').setValue(false);
      form.getField('max_devices').setValue(this.isMetricsPlan(form) ? 500 : 25);
    }

    if (this.isPlatformPlan(form)) {
      form.getField('metadata.resolution').setValue('std');
      if (type === PLAN_TYPES.edge.code) {
        // set default size for edge/core platform device plans
        form.getField('metadata.size').setValue(PLAN_SIZES['10GB'].code);
      }
      if (type === PLAN_TYPES.core.code) {
        form.getField('metadata.size').setValue(PLAN_SIZES['1GB'].code);
      }
    } else {
      form.getField('metadata.size').setValue('');
      form.getField('metadata.resolution').setValue('');
    }

    if (this.isPlatformPlan(form) || this.isCloudPlan(form) || this.isPak(form)) {
      form.getField('fast_retention').setValue(120);
      form.getField('full_retention').setValue(45);
    } else {
      form.getField('fast_retention').setValue(90);
      form.getField('full_retention').setValue(90);
    }

    if (this.isMetricsPlan(form)) {
      // Value only gets overwritten on new plan.
      form.getField('full_retention').setValue(400);
    }

    if (this.isSyntheticsPlan(form)) {
      form.getField('max_fps').setValue(10000);
      form.getField('fast_retention').setValue(365);
      form.getField('full_retention').setValue(365);
      form.getField('max_devices').setValue(1);
      form.getField('deviceTypes').setValue([routerDeviceTypeId]);
      form.getField('metadata.freeCredits').setValue(2.5 * 10 ** 6);
      form.getField('metadata.paidCredits').setValue(0);
      form.getField('name').setValue('Synthetics');
      form.getField('description').setValue('Synthetics Plan');
    } else {
      form.getField('metadata.freeCredits').setValue('');
      form.getField('metadata.paidCredits').setValue('');
      form.getField('name').setValue('');
      form.getField('description').setValue('');
    }

    this.updateRules(form);
  };

  renderSyntheticsFields(form) {
    return (
      <>
        <Field name="metadata.freeCredits">
          <InputGroup type="number" />
        </Field>
        <Field name="metadata.paidCredits">
          <InputGroup type="number" />
        </Field>
        <LargeLabeledValue
          label="Total Synthetics Credits"
          value={zeroToText(form.getValue('metadata.freeCredits') + form.getValue('metadata.paidCredits'), { fix: 0 })}
        />
      </>
    );
  }

  render() {
    const { model, onClose } = this.props;

    return (
      <FormDialog
        model={model}
        fields={this.fields}
        options={options}
        entityName="Plan"
        isOpen
        onClose={onClose}
        width={600}
        formActionsProps={{
          onSubmit: this.handleSave,
          onCancel: onClose,
          onRemove: this.handleExpire,
          showRemove: true
        }}
        formComponentProps={{
          onLoad: form => this.updateRules(form)
        }}
      >
        {({ form }) => {
          const isSynthPlan = this.isSyntheticsPlan(form);
          const isCloudPlan = this.isCloudPlan(form);
          const isPlatformPlan = this.isPlatformPlan(form);
          const isMetricsPlan = this.isMetricsPlan(form);
          return (
            <Flex flexWrap gutter={2} justifyContent="space-between">
              <Box flexAuto px={2}>
                <Field name="metadata.type" onChange={this.handlePlanTypeChange}>
                  <Select />
                </Field>
                {isSynthPlan && this.renderSyntheticsFields(form)}
                {isCloudPlan && (
                  <Field name="metadata.cloudProvider">
                    <Select />
                  </Field>
                )}
                {isPlatformPlan && (
                  <>
                    <Field name="metadata.resolution">
                      <Select />
                    </Field>
                    <Field name="metadata.size" options={this.getPlanSizeOptions(form)}>
                      <Select />
                    </Field>
                  </>
                )}
                <Field name="name">
                  <InputGroup autoFocus />
                </Field>
                <Field name="description">
                  <TextArea rows={4} />
                </Field>
                {(!isSynthPlan || !isMetricsPlan) && (
                  <Field name="bgp" showLabel={false}>
                    <Checkbox />
                  </Field>
                )}
                {!this.isCloudPlan(form) && !this.isSyntheticsPlan(form) && !this.isMetricsPlan(form) && (
                  <Field name="deviceTypes">
                    <CheckboxGroup />
                  </Field>
                )}
              </Box>
              <Box px={2} width={250}>
                {this.isPak(form) && (
                  <>
                    <Field name="metadata.pakFps">
                      <InputGroup type="number" />
                    </Field>
                    <Field name="metadata.isPak" showLabel={false}>
                      <Checkbox />
                    </Field>
                  </>
                )}
                <Field name="max_fps">
                  <InputGroup type="number" />
                </Field>

                <Field name="full_retention">
                  <InputGroup type="number" />
                </Field>
                {!isMetricsPlan && (
                  <Field name="fast_retention">
                    <InputGroup type="number" />
                  </Field>
                )}

                <Field name="max_devices">
                  <InputGroup type="number" />
                </Field>
              </Box>
            </Flex>
          );
        }}
      </FormDialog>
    );
  }
}
