import React, { Component } from 'react';
import { formConsumer } from 'core/form';
import { dateTimeFormatter } from 'core/util/dateUtils';
import AdminTable from 'app/components/admin/AdminTable';
import $dictionary from 'app/stores/$dictionary';
import { Card, Dialog, Flex, Icon } from 'core/components';
import CompanyPlansFormDialog from 'app/views/admin/plans/CompanyPlansFormDialog';
import { NewButton } from 'core/components/table';
import { observer } from 'mobx-react';

const getColumns = () => [
  {
    label: 'ID',
    name: 'id',
    width: 80
  },
  {
    label: 'Status',
    name: 'active',
    width: 100,
    renderer: ({ value }) => (value ? <Icon icon="tick" color="primary" /> : null)
  },
  {
    label: 'Name',
    name: 'name',
    flexBasis: 200
  },
  {
    label: 'Device Types',
    name: 'deviceTypes',
    flexBasis: 200,
    renderer: ({ value }) => value.map((type) => type.kflow_type).join(', ')
  },
  {
    label: 'Devices',
    computed: true,
    name: 'numDevices',
    width: 100
  },
  {
    label: 'Max Devices',
    name: 'max_devices',
    width: 110
  },
  {
    label: 'Max FPS',
    name: 'max_fps',
    width: 100
  },
  {
    label: 'BGP',
    name: 'bgp',
    width: 80,
    renderer: ({ value }) => (value ? <Icon icon="tick" color="primary" /> : null)
  },
  {
    label: 'Full',
    name: 'full_retention',
    width: 80
  },
  {
    label: 'Fast',
    name: 'fast_retention',
    width: 80
  },
  {
    label: 'Created',
    name: 'cdate',
    renderer: dateTimeFormatter
  },
  {
    label: 'Ended',
    computed: true,
    name: 'endDate',
    renderer: dateTimeFormatter
  },
  {
    name: 'metadata.type',
    label: 'Type',
    width: 75,
    renderer: ({ value }) => value || 'legacy'
  }
];

const PlanHistoryDialog = ({ plan, onDialogClose }) => (
  <Dialog isOpen title={`Plan History: ${plan.get('name')}`} onClose={onDialogClose} height={600} width="90%">
    <Dialog.Body display="flex" flexDirection="column">
      <Card display="flex" flex={1}>
        <AdminTable collection={plan.get('planHistory')} columns={getColumns()} noVirtualizedTable flexed />
      </Card>
    </Dialog.Body>
  </Dialog>
);

@formConsumer
@observer
export default class CompanyPlans extends Component {
  state = {
    selectedPlan: undefined,
    showEditDialog: false,
    showHistoryDialog: false
  };

  componentDidMount() {
    $dictionary.initializeDeviceTypes();
  }

  handleEditPlan = (plan) => {
    this.setState({ selectedPlan: plan, showEditDialog: true });
  };

  onEditDialogClose = () => {
    this.setState({ showEditDialog: false, selectedPlan: undefined });
  };

  handleShowHistory = (plan) => {
    this.setState({ selectedPlan: plan, showHistoryDialog: true });
  };

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false, selectedPlan: undefined });
  };

  onNewPlanClick = () => {
    const { form } = this.props;
    this.handleEditPlan(form.model.get('plans').forge({ company_id: form.model.get('id') }));
  };

  get kebabActions() {
    return [
      {
        id: 'edit',
        label: 'Edit',
        icon: 'edit',
        handler: (model) => this.handleEditPlan(model)
      },
      {
        id: 'history',
        label: 'Plan History',
        icon: 'time',
        disabled: (model) => !model.get('planHistory')?.size,
        handler: (model) => this.handleShowHistory(model)
      }
    ];
  }

  render() {
    const { form } = this.props;
    const { selectedPlan, showHistoryDialog, showEditDialog } = this.state;
    return (
      <Flex flexDirection="column" flex={1} overflow="auto">
        <AdminTable
          collection={form.model.get('plans')}
          columns={getColumns()}
          noVirtualizedTable
          containerProps={{ overflow: 'auto' }}
          tableContainerProps={{ overflow: 'auto' }}
          fetchCollectionOnMount={false}
          kebabActions={this.kebabActions}
          tableHeaderControlsPosition="afterSearch"
          tableHeaderControls={<NewButton ml={2} entityName="Plan" showIcon onClick={this.onNewPlanClick} />}
          flexed
        />
        {selectedPlan && showHistoryDialog && (
          <PlanHistoryDialog plan={selectedPlan} onDialogClose={this.onHistoryDialogClose} />
        )}
        {selectedPlan && showEditDialog && (
          <CompanyPlansFormDialog model={selectedPlan} company={form.model} onClose={this.onEditDialogClose} />
        )}
      </Flex>
    );
  }
}
