import React, { Component } from 'react';
import { DateTime, Field, formConsumer, Switch, Select } from 'core/form';
import { COMPANY_PLAN_MAP, COMPANY_PLATFORM_MAP, COMPANY_STATUS_MAP } from 'app/stores/sudo/util';
import { Box, Flex } from 'core/components';
import { observer } from 'mobx-react';
import moment from 'moment/moment';
import AccountTeamCard from 'app/views/licenses/AccountTeamCard';
import { buildOptionsFromMap } from '../../../core/form/components/modalSelect/selectHelpers';

@formConsumer
@observer
export default class CompanyOverview extends Component {
  render() {
    const { form } = this.props;
    return (
      <Flex gutter={2} justifyContent="space-between">
        <Box>
          <Flex mb={2}>
            <Field name="company_plan_id" options={buildOptionsFromMap(COMPANY_PLAN_MAP)} mr={2}>
              <Select />
            </Field>
            {form.getValue('company_plan_id') === 'TRIAL' && (
              <Field name="trial_end_date" mr={2}>
                <DateTime fill format="YYYY-MM-DD" timePrecision={null} minDate={moment().toDate()} />
              </Field>
            )}
            <Field name="company_status" options={buildOptionsFromMap(COMPANY_STATUS_MAP)} mr={2}>
              <Select />
            </Field>
            <Field name="platform" options={buildOptionsFromMap(COMPANY_PLATFORM_MAP)}>
              <Select />
            </Field>
          </Flex>
          <Flex mb={2}>
            <Field name="settings.excludeDataRights" mr={2}>
              <Switch />
            </Field>
            <Field name="settings.allowBGPSharing">
              <Switch />
            </Field>
          </Flex>
          <Flex flex={1}>
            <Field name="settings.tags" flex={1}>
              <Select multi fill />
            </Field>
          </Flex>
        </Box>
        {form.model.get('accountTeam')?.length > 0 && (
          <Box>
            <AccountTeamCard accountTeam={form.model.get('accountTeam')} hideContact />
          </Box>
        )}
      </Flex>
    );
  }
}
